.fc-event {
  

  
  &.fc-event-end:not(.tour-event)  {
    margin-right: 25px !important;
  }

  &.fc-event-start:not(.tour-event) {
    margin-left: 25px !important;
  }

  &.fc-not-end {
    margin-right: 0 !important;
  }

  &.fc-not-start {
    margin-left: 0 !important;
  }
}

.fc-body {
  .fc-resource-area {
    width: 15%;

    .fc-cell-content {
      text-overflow: ellipsis;
    }
  }
}

.fc {
  .fc-datagrid-cell-cushion {
    padding: 5px;
  }

  .fc-resource {
    .fc-icon {
      display: none;
    }
  }
}


.fc-day-sun {
  background: antiquewhite;
}

.fc-day-today {
  background: pink;
}